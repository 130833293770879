import { Button, Grommet, Heading, Image } from 'grommet';

import { CONTENT } from '../constants/content';
import Layout from '../components/Layout/Layout';
import Main from '../components/Main/_ Main';
import { config } from '../configs/config';
import { siteTheme } from '../configs/siteTheme';

/**
 * Simple component to render 404 experience
 */
export default function Custom404() {
  return (
    <Grommet theme={siteTheme}>
      <Layout renderNonPostContent={true}>
        <Main>
          <>
            <Image
              fit="cover"
              src={config.links.images.pageNotFound}
              style={{
                height: '150px',
                width: '150px',
                marginTop: '100px',
                alignSelf: 'center'
              }}
            />
            <Heading textAlign={'center'}>{CONTENT.TEXT.OOPS}</Heading>
            <Button
              primary
              label={CONTENT.BUTTONS.TAKE_ME_BACK}
              onClick={() => {
                window.location = config.links.images.home;
              }}
            ></Button>
          </>
        </Main>
      </Layout>
    </Grommet>
  );
}
